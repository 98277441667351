import OpenAPIClientAxios from 'openapi-client-axios';
import { create } from '@postinumero/use-async';
import definition from './../api.json';
const api = new OpenAPIClientAxios({ definition: definition });
export const clientPromise = api.init();
export async function getFormsRaw(...args) {
    const client = await clientPromise;
    return await client['getForms'](...args);
}
export async function putFormRaw(...args) {
    const client = await clientPromise;
    return await client['putForm'](...args);
}
export async function postFormRaw(...args) {
    const client = await clientPromise;
    return await client['postForm'](...args);
}
export async function getDraftFormsRaw(...args) {
    const client = await clientPromise;
    return await client['getDraftForms'](...args);
}
export async function getPublishedFormsRaw(...args) {
    const client = await clientPromise;
    return await client['getPublishedForms'](...args);
}
export async function getPublishedPublicFormsRaw(...args) {
    const client = await clientPromise;
    return await client['getPublishedPublicForms'](...args);
}
export async function getFormTagsRaw(...args) {
    const client = await clientPromise;
    return await client['getFormTags'](...args);
}
export async function getFormHandlersRaw(...args) {
    const client = await clientPromise;
    return await client['getFormHandlers'](...args);
}
export async function getFormRaw(...args) {
    const client = await clientPromise;
    return await client['getForm'](...args);
}
export async function deleteFormRaw(...args) {
    const client = await clientPromise;
    return await client['deleteForm'](...args);
}
export async function getFormsByIdsRaw(...args) {
    const client = await clientPromise;
    return await client['getFormsByIds'](...args);
}
export async function getFormRevRaw(...args) {
    const client = await clientPromise;
    return await client['getFormRev'](...args);
}
export async function getAtomicFormRaw(...args) {
    const client = await clientPromise;
    return await client['getAtomicForm'](...args);
}
export async function getFormGroupsRaw(...args) {
    const client = await clientPromise;
    return await client['getFormGroups'](...args);
}
export async function putFormGroupRaw(...args) {
    const client = await clientPromise;
    return await client['putFormGroup'](...args);
}
export async function postFormGroupRaw(...args) {
    const client = await clientPromise;
    return await client['postFormGroup'](...args);
}
export async function getFormGroupRaw(...args) {
    const client = await clientPromise;
    return await client['getFormGroup'](...args);
}
export async function deleteFormGroupRaw(...args) {
    const client = await clientPromise;
    return await client['deleteFormGroup'](...args);
}
export async function getFieldsRaw(...args) {
    const client = await clientPromise;
    return await client['getFields'](...args);
}
export async function putFieldRaw(...args) {
    const client = await clientPromise;
    return await client['putField'](...args);
}
export async function postFieldRaw(...args) {
    const client = await clientPromise;
    return await client['postField'](...args);
}
export async function getFieldRaw(...args) {
    const client = await clientPromise;
    return await client['getField'](...args);
}
export async function deleteFieldRaw(...args) {
    const client = await clientPromise;
    return await client['deleteField'](...args);
}
export async function postFormDataRaw(...args) {
    const client = await clientPromise;
    return await client['postFormData'](...args);
}
export async function postResubmitFormDataRaw(...args) {
    const client = await clientPromise;
    return await client['postResubmitFormData'](...args);
}
export async function getFormDataRaw(...args) {
    const client = await clientPromise;
    return await client['getFormData'](...args);
}
export async function putFormDataRaw(...args) {
    const client = await clientPromise;
    return await client['putFormData'](...args);
}
export async function getFormAndFormDataByRevisionRaw(...args) {
    const client = await clientPromise;
    return await client['getFormAndFormDataByRevision'](...args);
}
export async function getFormDataFromSubmissionHandlerRaw(...args) {
    const client = await clientPromise;
    return await client['getFormDataFromSubmissionHandler'](...args);
}
export async function getConfigsRaw(...args) {
    const client = await clientPromise;
    return await client['getConfigs'](...args);
}
export async function postConfigsRaw(...args) {
    const client = await clientPromise;
    return await client['postConfigs'](...args);
}
export async function getConfigRaw(...args) {
    const client = await clientPromise;
    return await client['getConfig'](...args);
}
export async function getForms(...args) {
    const response = await getFormsRaw(...args);
    return response.data;
}
export async function putForm(...args) {
    const response = await putFormRaw(...args);
    return response.data;
}
export async function postForm(...args) {
    const response = await postFormRaw(...args);
    return response.data;
}
export async function getDraftForms(...args) {
    const response = await getDraftFormsRaw(...args);
    return response.data;
}
export async function getPublishedForms(...args) {
    const response = await getPublishedFormsRaw(...args);
    return response.data;
}
export async function getPublishedPublicForms(...args) {
    const response = await getPublishedPublicFormsRaw(...args);
    return response.data;
}
export async function getFormTags(...args) {
    const response = await getFormTagsRaw(...args);
    return response.data;
}
export async function getFormHandlers(...args) {
    const response = await getFormHandlersRaw(...args);
    return response.data;
}
export async function getForm(...args) {
    const response = await getFormRaw(...args);
    return response.data;
}
export async function deleteForm(...args) {
    const response = await deleteFormRaw(...args);
    return response.data;
}
export async function getFormsByIds(...args) {
    const response = await getFormsByIdsRaw(...args);
    return response.data;
}
export async function getFormRev(...args) {
    const response = await getFormRevRaw(...args);
    return response.data;
}
export async function getAtomicForm(...args) {
    const response = await getAtomicFormRaw(...args);
    return response.data;
}
export async function getFormGroups(...args) {
    const response = await getFormGroupsRaw(...args);
    return response.data;
}
export async function putFormGroup(...args) {
    const response = await putFormGroupRaw(...args);
    return response.data;
}
export async function postFormGroup(...args) {
    const response = await postFormGroupRaw(...args);
    return response.data;
}
export async function getFormGroup(...args) {
    const response = await getFormGroupRaw(...args);
    return response.data;
}
export async function deleteFormGroup(...args) {
    const response = await deleteFormGroupRaw(...args);
    return response.data;
}
export async function getFields(...args) {
    const response = await getFieldsRaw(...args);
    return response.data;
}
export async function putField(...args) {
    const response = await putFieldRaw(...args);
    return response.data;
}
export async function postField(...args) {
    const response = await postFieldRaw(...args);
    return response.data;
}
export async function getField(...args) {
    const response = await getFieldRaw(...args);
    return response.data;
}
export async function deleteField(...args) {
    const response = await deleteFieldRaw(...args);
    return response.data;
}
export async function postFormData(...args) {
    const response = await postFormDataRaw(...args);
    return response.data;
}
export async function postResubmitFormData(...args) {
    const response = await postResubmitFormDataRaw(...args);
    return response.data;
}
export async function getFormData(...args) {
    const response = await getFormDataRaw(...args);
    return response.data;
}
export async function putFormData(...args) {
    const response = await putFormDataRaw(...args);
    return response.data;
}
export async function getFormAndFormDataByRevision(...args) {
    const response = await getFormAndFormDataByRevisionRaw(...args);
    return response.data;
}
export async function getFormDataFromSubmissionHandler(...args) {
    const response = await getFormDataFromSubmissionHandlerRaw(...args);
    return response.data;
}
export async function getConfigs(...args) {
    const response = await getConfigsRaw(...args);
    return response.data;
}
export async function postConfigs(...args) {
    const response = await postConfigsRaw(...args);
    return response.data;
}
export async function getConfig(...args) {
    const response = await getConfigRaw(...args);
    return response.data;
}
export const [useFormsRaw, refetchForms, useFormsRawSafe] = create(getFormsRaw, { id: 'react-openapi-client-generator-getForms' });
export const [useDraftFormsRaw, refetchDraftForms, useDraftFormsRawSafe] = create(getDraftFormsRaw, { id: 'react-openapi-client-generator-getDraftForms' });
export const [usePublishedFormsRaw, refetchPublishedForms, usePublishedFormsRawSafe] = create(getPublishedFormsRaw, { id: 'react-openapi-client-generator-getPublishedForms' });
export const [usePublishedPublicFormsRaw, refetchPublishedPublicForms, usePublishedPublicFormsRawSafe] = create(getPublishedPublicFormsRaw, { id: 'react-openapi-client-generator-getPublishedPublicForms' });
export const [useFormTagsRaw, refetchFormTags, useFormTagsRawSafe] = create(getFormTagsRaw, { id: 'react-openapi-client-generator-getFormTags' });
export const [useFormHandlersRaw, refetchFormHandlers, useFormHandlersRawSafe] = create(getFormHandlersRaw, { id: 'react-openapi-client-generator-getFormHandlers' });
export const [useFormRaw, refetchForm, useFormRawSafe] = create(getFormRaw, { id: 'react-openapi-client-generator-getForm' });
export const [useFormsByIdsRaw, refetchFormsByIds, useFormsByIdsRawSafe] = create(getFormsByIdsRaw, { id: 'react-openapi-client-generator-getFormsByIds' });
export const [useFormRevRaw, refetchFormRev, useFormRevRawSafe] = create(getFormRevRaw, { id: 'react-openapi-client-generator-getFormRev' });
export const [useAtomicFormRaw, refetchAtomicForm, useAtomicFormRawSafe] = create(getAtomicFormRaw, { id: 'react-openapi-client-generator-getAtomicForm' });
export const [useFormGroupsRaw, refetchFormGroups, useFormGroupsRawSafe] = create(getFormGroupsRaw, { id: 'react-openapi-client-generator-getFormGroups' });
export const [useFormGroupRaw, refetchFormGroup, useFormGroupRawSafe] = create(getFormGroupRaw, { id: 'react-openapi-client-generator-getFormGroup' });
export const [useFieldsRaw, refetchFields, useFieldsRawSafe] = create(getFieldsRaw, { id: 'react-openapi-client-generator-getFields' });
export const [useFieldRaw, refetchField, useFieldRawSafe] = create(getFieldRaw, { id: 'react-openapi-client-generator-getField' });
export const [useFormDataRaw, refetchFormData, useFormDataRawSafe] = create(getFormDataRaw, { id: 'react-openapi-client-generator-getFormData' });
export const [useFormAndFormDataByRevisionRaw, refetchFormAndFormDataByRevision, useFormAndFormDataByRevisionRawSafe] = create(getFormAndFormDataByRevisionRaw, { id: 'react-openapi-client-generator-getFormAndFormDataByRevision' });
export const [useFormDataFromSubmissionHandlerRaw, refetchFormDataFromSubmissionHandler, useFormDataFromSubmissionHandlerRawSafe] = create(getFormDataFromSubmissionHandlerRaw, { id: 'react-openapi-client-generator-getFormDataFromSubmissionHandler' });
export const [useConfigsRaw, refetchConfigs, useConfigsRawSafe] = create(getConfigsRaw, { id: 'react-openapi-client-generator-getConfigs' });
export const [useConfigRaw, refetchConfig, useConfigRawSafe] = create(getConfigRaw, { id: 'react-openapi-client-generator-getConfig' });
export function useForms(...args) {
    return useFormsRaw(...args).data;
}
export function useDraftForms(...args) {
    return useDraftFormsRaw(...args).data;
}
export function usePublishedForms(...args) {
    return usePublishedFormsRaw(...args).data;
}
export function usePublishedPublicForms(...args) {
    return usePublishedPublicFormsRaw(...args).data;
}
export function useFormTags(...args) {
    return useFormTagsRaw(...args).data;
}
export function useFormHandlers(...args) {
    return useFormHandlersRaw(...args).data;
}
export function useForm(...args) {
    return useFormRaw(...args).data;
}
export function useFormsByIds(...args) {
    return useFormsByIdsRaw(...args).data;
}
export function useFormRev(...args) {
    return useFormRevRaw(...args).data;
}
export function useAtomicForm(...args) {
    return useAtomicFormRaw(...args).data;
}
export function useFormGroups(...args) {
    return useFormGroupsRaw(...args).data;
}
export function useFormGroup(...args) {
    return useFormGroupRaw(...args).data;
}
export function useFields(...args) {
    return useFieldsRaw(...args).data;
}
export function useField(...args) {
    return useFieldRaw(...args).data;
}
export function useFormData(...args) {
    return useFormDataRaw(...args).data;
}
export function useFormAndFormDataByRevision(...args) {
    return useFormAndFormDataByRevisionRaw(...args).data;
}
export function useFormDataFromSubmissionHandler(...args) {
    return useFormDataFromSubmissionHandlerRaw(...args).data;
}
export function useConfigs(...args) {
    return useConfigsRaw(...args).data;
}
export function useConfig(...args) {
    return useConfigRaw(...args).data;
}
export function useFormsSafe(...args) {
    const [error, response] = useFormsRawSafe(...args);
    return [error, response?.data];
}
export function useDraftFormsSafe(...args) {
    const [error, response] = useDraftFormsRawSafe(...args);
    return [error, response?.data];
}
export function usePublishedFormsSafe(...args) {
    const [error, response] = usePublishedFormsRawSafe(...args);
    return [error, response?.data];
}
export function usePublishedPublicFormsSafe(...args) {
    const [error, response] = usePublishedPublicFormsRawSafe(...args);
    return [error, response?.data];
}
export function useFormTagsSafe(...args) {
    const [error, response] = useFormTagsRawSafe(...args);
    return [error, response?.data];
}
export function useFormHandlersSafe(...args) {
    const [error, response] = useFormHandlersRawSafe(...args);
    return [error, response?.data];
}
export function useFormSafe(...args) {
    const [error, response] = useFormRawSafe(...args);
    return [error, response?.data];
}
export function useFormsByIdsSafe(...args) {
    const [error, response] = useFormsByIdsRawSafe(...args);
    return [error, response?.data];
}
export function useFormRevSafe(...args) {
    const [error, response] = useFormRevRawSafe(...args);
    return [error, response?.data];
}
export function useAtomicFormSafe(...args) {
    const [error, response] = useAtomicFormRawSafe(...args);
    return [error, response?.data];
}
export function useFormGroupsSafe(...args) {
    const [error, response] = useFormGroupsRawSafe(...args);
    return [error, response?.data];
}
export function useFormGroupSafe(...args) {
    const [error, response] = useFormGroupRawSafe(...args);
    return [error, response?.data];
}
export function useFieldsSafe(...args) {
    const [error, response] = useFieldsRawSafe(...args);
    return [error, response?.data];
}
export function useFieldSafe(...args) {
    const [error, response] = useFieldRawSafe(...args);
    return [error, response?.data];
}
export function useFormDataSafe(...args) {
    const [error, response] = useFormDataRawSafe(...args);
    return [error, response?.data];
}
export function useFormAndFormDataByRevisionSafe(...args) {
    const [error, response] = useFormAndFormDataByRevisionRawSafe(...args);
    return [error, response?.data];
}
export function useFormDataFromSubmissionHandlerSafe(...args) {
    const [error, response] = useFormDataFromSubmissionHandlerRawSafe(...args);
    return [error, response?.data];
}
export function useConfigsSafe(...args) {
    const [error, response] = useConfigsRawSafe(...args);
    return [error, response?.data];
}
export function useConfigSafe(...args) {
    const [error, response] = useConfigRawSafe(...args);
    return [error, response?.data];
}
